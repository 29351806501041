<template>
<div style="max-width: 800px">

  <div class="card-box">
    <h4>Number Profiles</h4>
    <p>View all the data we have for phone numbers</p>
    <CButton @click="loadPath('profiles/numbers')" color="info" class="card-box-button">
      Number Profiles
    </CButton>
  </div>

  <div class="card-box">
    <h4>SMS Sender Profile</h4>
    <p>View all the data we have for a specific sender</p>
    <CButton @click="loadPath('profiles/sender')" color="info" class="card-box-button">
      SMS Sender Profile
    </CButton>
  </div>

  <div class="card-box">
    <h4>SMS Fingerprint Profile</h4>
    <p>View all the data we have for a specific fingerprint</p>
    <CButton @click="loadPath('profiles/fingerprint')" color="info" class="card-box-button">
      SMS Fingerprint Profile
    </CButton>
  </div>

  <div class="card-box">
    <h4>Domain Profile</h4>
    <p>View all the data we have for a specific domain</p>
    <CButton @click="loadPath('profiles/domain')" color="info" class="card-box-button">
      Domain Profile
    </CButton>
  </div>

</div>
</template>

<script>
export default {
  name: 'Profile',
  methods: {
    //--------------------------------------------------------------------------
    loadPath(path) {
      this.$router.push({
        path: path
      });
    },
  }
}
</script>
